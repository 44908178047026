import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { useIntersectionObserver } from '@/lib/hooks';

import type { TypeVideoProps } from './types';

export type VideoHandle = {
  start: () => void;
};

/**
 * Video
 */
export const Video = forwardRef<VideoHandle, TypeVideoProps>(function Video(
  {
    className,
    sources,
    autoplay = false,
    loop = true,
    muted = true,
    playsInline = true,
    disablePictureInPicture = true,
    manualPlay = false,
    ...props
  },
  ref,
) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const entry = useIntersectionObserver(videoRef, {
    freezeOnceVisible: false,
  });

  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (manualPlay) return;

    isVisible ? videoRef.current?.play() : videoRef.current?.pause();
  }, [isVisible, manualPlay]);

  useImperativeHandle(ref, () => ({
    start() {
      videoRef.current?.play();
    },
  }));

  return (
    <video
      preload="none"
      autoPlay={autoplay}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      ref={videoRef}
      disablePictureInPicture={disablePictureInPicture}
      {...props}
      {...(className && { className })}
    >
      {sources.map((source, index) => (
        <source src={source.src} type={source.type} key={index} />
      ))}
    </video>
  );
});
